//
// Basic Bootstrap table
//
.table {
    font-size: $table-font-size;
    //border-collapse: separate;
    h1,
    h2,
    h3,
    h4,
    p,
    ul {
        &:only-child {
            line-height: inherit;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    th {
        color: $headings-color;
        font-weight: $font-weight-bold;
    }
    thead,
    tbody,
    tfoot {
        &:last-child {
            tr:last-child {
                th,
                td {
                    border-bottom: $table-border-width solid $table-border-color;
                }
            }
        }
    }
}

.table {
    .thead-dark,
    .thead-light {
        th {
            border-top: 0;
        }
    }

}

.table-valign-center {
    th,
    td {
        vertical-align: middle;
    }
}
