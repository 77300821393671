$ita-box-shadow: 1px 10px 20px 0px rgba(0, 0, 0, .05), 0 0px 0px 0 rgba(0, 0, 0, .12), 0 0px 5px -1px rgba(0, 0, 0, .1);

@import "custom/e-paragraph-card";
@import "custom/e-header";
@import "custom/e-footer";
@import "custom/ita-page-loading";
@import "custom/ita-animations";

.s-section-margin-alignment {
    margin-left: 4.4vw;
    margin-right: 4.4vw;
    @include media-breakpoint-up(xl) {
        margin-left: 6vw;
        margin-right: 6vw;
    }
    @media (min-width: 1920px) {
        margin-left: 17vw;
        margin-right: 17vw;
        padding-right: 2vw;
        padding-left: 2vw;
    }
}

.ita-box-right-bottom {
    &::before {
        content: "";
        position: absolute;
        left: 4em;
        bottom: -4em;
        top: 2em;
        background-size: 4px 4px;
    }
}

.opacity-01 {
    >.p-img-container {
        opacity: 0.1;
    }
}

@include media-breakpoint-down(md) {
    .e-section.px-xs-3 {
        padding-right: 6vw;
        padding-left: 6vw;
    }
    .p-tile-height-md, .p-tile-height-sm {
        height: auto;
    }
}

.do-not-show-specialer { 
    .col-12.dita-specialer {
        display: none;
    }
}

.dita-distributor {
    select.dita-lawyer-selector {
        width: 100%;
        font-size: 1em;
        height: 3em;
        border: 1px solid $gray-300;
        border-radius: 0;
        background-color: #fff;
    }
    @include media-breakpoint-down(xs) {
        .dita-lawyer {
            border-bottom: 1px solid $gray-100;
        }
    }
}