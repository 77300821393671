.p-card-img-bleed .p-img-container .p-img {
    border-radius: 0;
}

.p-card-shadow-small {
    .card {
        box-shadow: $ita-box-shadow;
        transition: $transition-fade-speed ease all;
    }
    &:not(.p-card-hover-disabled):hover {
        .card {
            box-shadow: 0 2px 2px rgba(45,45,45,.05), 0 4px 4px rgba(49,49,49,.05), 0 8px 8px rgba(42,42,42,.05), 0 16px 16px rgba(32,32,32,.05), 0 32px 32px rgba(49,49,49,.05), 0 64px 64px rgba(35,35,35,.05);
        }
    }
}