.p-section {
    position: relative;
    border: 0 solid $border-color;
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
}

//Background size and position
.p-section-bg-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
// Borders
.p-section-border-top {
    border-top-width: $border-width;
}

.p-section-border-bottom {
    border-bottom-width: $border-width;
}

.p-section-collapse {
    >.container-fluid {
        >.row {
            &::after {
                content: "";
                display: block;
                flex: 0 0 100%;
                order: 2;
            }
        }
    }
}

.p-section-collapse-content {
    &:not(.show) {
        display: block;
        overflow: hidden;
        height: 25vh;
        &.is-sm {
            height: 12.5vh;
        }
        &.is-lg {
            height: 37.5vh;
        }
    }
    &::after {
        content: "";
        display: block;
        height: $spacer;
    }
}

.p-section-collapse-content.collapsing:not(.show) {
    //height: 25vh;
}

.p-section-collapse-toggle {
    z-index: 99;
    cursor: pointer;
    font-size: $font-size-sm;
    &::before {
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        bottom: 100%;
        height: 0;
        transition: height $transition-collapse-speed * 2 $transition-collapse-easing;
    }
    &.collapsed {
        &::before {
            height: $spacer * 0.25;
            background: radial-gradient(50% 100% at 50% 100%, rgba(0, 0, 0, 0.15) 0%, transparent 100%), radial-gradient(50% 25% at 50% 100%, rgba(0, 0, 0, 0.35) 0%, transparent 100%);
        }
    }
}

.p-section-collapse-toggle-text {
    margin-top: $spacer * -0.25;
    display: block;
    font-size: $font-size-sm;
    &::after {
        content: attr(data-collapse-text);
    }
    .collapsed & {
        &::after {
            content: attr(data-expand-text);
        }
    }
}

.p-section-collapse-toggle-btn {
    display: block;
    padding: 0;
    height: $spacer;
    width: $spacer;
    line-height: 1;
    margin: 0 auto;
    font-size: $spacer;
    box-shadow: $shadow-sm;
    transform: translateY(-50%);
    transition: none;
    &:hover,
    &:active {
        // background: e-theme-color("dark", "bg");
        // color: $white;
        // border-color: e-theme-color("dark", "bg");
    }
}

.p-section-collapse-toggle-icon {
    line-height: 1.25;
    transform: rotate(540deg);
    transition: transform $transition-collapse-speed $transition-collapse-easing;
    .collapsed & {
        transform: rotate(0deg) translateY(0.0675em);
    }
}
