.dropdown-toggle {
    //Menu hover/active element
    &::after {
        display: none;
    }
    .e-nav-pageicon-dropdown {
        margin: 0 0.125em;
        transform: rotate(0deg) translate(0, 0.025em);
        transition: transform $transition-collapse-speed $transition-collapse-easing;
    }
    .dropdown.show & {
        //Menu hover/active element
        &::after {
            display: none;
        }
        .e-nav-pageicon-dropdown {
            transform: rotate(-540deg) translate(0, 0);
        }
    }
}

// The dropdown menu
.dropdown-menu {
    font-size: $small-font-size;
    width: 10 * $spacer;
    color: inherit;
    > .nav {
    }
    > .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: $spacer / 2;
    }
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {

    white-space: normal;
    line-height: $line-height-sm;
    // Active state
    // &.is-active {
    //     color: $dropdown-link-active-color;
    //     background-color: $dropdown-link-active-bg;
    // }
}

// Dropdown section headers
.dropdown-header {
    padding-top: $dropdown-padding-y * 1.125;
    font-size: 1em;
    white-space: normal;
    border-bottom: 1px solid $dropdown-divider-bg;
}
