.ita-loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -50px;
    width: 100px;
    height: 100px;
    z-index: 2;
    .path {
        position: absolute;
        animation: s-loading-spinner-scale 1.5s ease-in-out infinite;
        opacity: 0;
        fill: theme-color("primary");

        &.circle1 {
            transform-origin: 20%;
        }

        &.circle2 {
            transform-origin: 40%;
            animation-delay: .25s;
        }

        &.circle3 {
            transform-origin: 60%;
            animation-delay: .5s;
        }
        &.circle4 {
            transform-origin: 80%;
            animation-delay: .75s;
        }
    }
}


@keyframes s-loading-spinner-scale {

    0% {
        opacity: 0;
        transform: scale(0);
    }
    50% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0);
    }

}

