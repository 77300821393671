.e-section {
    @include e-canvas-padding;
    @for $size from 1 through 5 {
        &.pt-#{$size},
        &.py-#{$size} {
            padding-top: ($spacer * $size) !important;
        }
        &.pb-#{$size},
        &.py-#{$size} {
            padding-bottom: ($spacer * $size) !important;
        }
    }
    &:not(.e-nav-local) {
        &.is-sticky {
            position: sticky;
            top: 0;
            z-index: $zindex-sticky;
            transition: padding $transition-collapse-speed / 2 $transition-collapse-easing, box-shadow $transition-fade-speed / 2 $transition-fade-easing;
        }
        &.is-stuck {
            box-shadow: $shadow-xs;
            &:not([class*="e-section-height-"]) {
                box-shadow: $shadow-md;
                //@for $size from 1 through 5 {
                //    &.pt-#{$size},
                //    &.py-#{$size} {
                //        padding-top: ($spacer / 3 * $size) !important;
                //    }
                //    &.pb-#{$size},
                //    &.py-#{$size} {
                //        padding-bottom: ($spacer / 3 * $size) !important;
                //    }
                //}
            }
        }
    }
}

// Espresso section height
@each $key, $value in $section-heights {
    .e-section-height-#{$key} {
        @include e-section-height($key);
    }
}

.e-section-height-fullscreen {
    min-height: 100vh;
}
