// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff
// Kill the scroll on the body
.modal-open {
    //http://stackoverflow.com/questions/32675849/screen-zooms-in-when-a-bootstrap-modal-is-opened-on-ios-9-safari
    // min-height: 100vh;
    // -webkit-overflow-scrolling: auto;
    &.modal-open-no-adjustment {
        overflow-y: auto;
        padding-right: 0 !important;
    }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    align-items: center;
    .close {
        padding: 0;
        margin: 0;
    }
}

// Modal sizes
.modal-fullscreen {
    // -webkit-overflow-scrolling: touch;
    .modal-open & {
        overflow-y: scroll;
        padding-right: 0 !important;
    }
    .modal-dialog {
        max-width: 100%;
        margin: 0;
        transition: none;
    }
    .modal-content {
        min-height: 100vh;
        border: 0;
        border-radius: 0;
        box-shadow: none;
    }
    // Modal header
    // Top section of the modal w/ title and dismiss
    .modal-header {
        position: sticky;
        top: 0;
        z-index: 2;
        background: $header-bg;
        padding-left: (e-strip-unit($grid-gutter-width / 2) * 1vw + $canvas-padding-sm);
        padding-right: (e-strip-unit($grid-gutter-width / 2) * 1vw + $canvas-padding-sm);
    }
    // Title text within header
    .modal-title {

    }

    // Modal body
    // Where all modal content resides (sibling of .modal-header and .modal-footer)
    .modal-body {
        padding-left: (e-strip-unit($grid-gutter-width / 2) * 1vw + $canvas-padding-sm);
        padding-right: (e-strip-unit($grid-gutter-width / 2) * 1vw + $canvas-padding-sm);
        .close {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
            padding: $spacer;
        }
    }

    // Footer (for actions)
    .modal-footer {
        padding-left: (e-strip-unit($grid-gutter-width / 2) * 1vw + $canvas-padding-sm);
        padding-right: (e-strip-unit($grid-gutter-width / 2) * 1vw + $canvas-padding-sm);
    }
}
