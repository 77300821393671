.e-cart {
    position: relative;
    .e-loading-overlay {
        background: rgba($loading-bg, .85);
    }
}

.e-cart-header {

}

.e-cart-body {

}

.e-cart-footer {

}

.e-orderline {
    position: relative;
    &::before {
        content: '';
        background: theme-color('info');
        position: absolute;
        top: $border-width * -1;
        right: $card-spacer-x * -1;
        bottom: $border-width * -1;
        left: $card-spacer-x * -1;
        opacity: 0;
        will-change: opacity;
        transition: opacity $transition-fade-easing $transition-fade-speed / 2;
    }
    &.has-focus {
        &::before {
            opacity: 1;
        }
    }
    + .e-orderline {
        border-top: $border-width solid $border-color;
    }
    &.has-focus + .e-orderline {
         border-color: transparent;
    }
    .e-orderline-delete-undo-btn{
        display: none;
    }
    &.is-deleted{
        .e-orderline-delete-undo-btn{
            display: inline-block;
        }
        .e-orderline-data{
            opacity: $text-muted / 2;
            pointer-events: none;
        }
    }
    .form-control {
        max-width: $spacer * 2.5;
    }
}

@include media-breakpoint-up(md) {
    .e-cart-summery {
        position: sticky;
        top: $spacer;
    }
}
