.e-header {
    position: relative;
    z-index: $zindex-fixed;
    color: $header-color;
    background: $header-bg;
    transform: translateY(0);
    transition: transform $transition-slide-speed $transition-slide-easing;
    will-change: transform;
    &.is-sticky {
        position: sticky;
        top: 0;
        //right: 0;
        //left: 0;
    }
    &.is-stuck {
        box-shadow: $shadow-md;
    }
    &.is-hidden {
        transform: translateY(-110%);
    }
    &.is-transparent {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        color: rgba(255, 255, 255, 1);
        background: none;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: -50%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 95%);
            pointer-events: none;
        }
        &.is-stuck {
            box-shadow: none;
        }
    }
}

@include media-breakpoint-down($nav-hamburger-breakpoint) {
    .e-nav {
        height: 100vh;
        color: $header-color;
        background: $header-bg;
        transform: translate3d(-101%, 0, 0);
        will-change: transform;
        transition: transform $transition-slide-speed $transition-slide-easing;
        &.show {
            transform: translate3d(0, 0, 0);
            transition: transform ($transition-slide-speed / 1.5) $transition-slide-easing;
        }
        // Overrides to fix collapse vs. dropdown conflicts.
        .collapse {
            display: none;
        }
        .collapse.show {
            display: block;
        }
        .dropdown-toggle::after {
            display: none;
        }
        .dropdown-menu {
            position: static;
            float: none;
            min-width: 100%;
            border: 0;
            box-shadow: none;
            background: none;
            padding: 0;
            font-size: inherit;
        }
        .dropdown-item {
            background: none;
        }
        // Overrides end
        .e-nav-container {
            color: $header-color;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            .e-header-section {
                background: none !important;
                display: flex;
                flex-direction: column;
                flex: 0 0 100%;
                width: 100%;
            }
            .e-header-section-content {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            // Size and flexbox props for all nav containers.
            // https://github.com/philipwalton/flexbugs#flexbug-11
            .e-logo {
                flex: 0 0 75%;
                width: 75%;
                order: 1;
            }
            .e-nav-global {
                flex: 0 0 100%;
                width: 100%;
                order: 4;
            }
            .e-nav-utilities-primary {
                flex: 0 0 100%;
                width: 100%
            }
            .e-nav-utilities-secondary {
                flex: 0 0 100%;
                width: 100%
            }
            .e-nav-hamburger-aside {
                flex: 0 1 25%;
                width: 25%;
                min-height: 100vh;
            }
            .e-nav-item-hamburger-close {
                flex: 0 1 25%;
                width: 25%;
                order: 2;
            }

        }
    }
}

//Header styling for larger breakpoints.
@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    .e-header {
    }
    .e-header-section {
        > .container-fluid {
            > .row {
                > .col-12 {
                    position: static;
                }
            }
        }
    }
    .e-header-section-primary {
    }
    .e-header-section-secondary {
        background: theme-color("light");
    }
    .e-header-section-content {
        position: static;
        display: flex;
    }
    .e-nav {
        display: block;
        position: static;
        overflow: visible;
        opacity: 1;
        .e-nav-container {
            color: $header-color;
        }
        //Size and flexbox props for all nav containers.
        .e-logo {
            order: 1;
        }
        .e-nav-global {
            order: 2;
            margin-left: auto;
        }
        .e-nav-utilities-primary {
            order: 2;
            flex: 1 1 100%;
        }
        .e-nav-utilities-secondary {
            .e-header & {
                display: none;
            }
        }
        // Pseudo element to force wrap flex items.
        // &::after {
        //     content: "";
        //     order: 6;
        //     flex: 0 0 100%;
        // }
    }
}
