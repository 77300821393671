// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//
// Color system
//

// stylelint-disable
$white: #fff;
$gray-100: #f5f5f5;
$gray-200: #eee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
$black: rgba(0, 0, 0, 0.95);

$grays: ();
$grays: map-merge((
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
), $grays);

$blue: #4b8ce1;
$indigo: #3f51b5;
$purple: #9c27b0;
$pink: #e91e63;
$red: #f44336;
$orange: #ff9800;
$yellow: #ffeb3b;
$green: #28af5f;
$teal: #009688;
$cyan: #00bcd4;
$azure: #ebfbff;

$colors: ();
$colors: map-merge((
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "azure": $azure,
        "white": $white,
        "gray": $gray-600,
        "black": $black,
), $colors);

// Bootstrap theme colors
$theme-colors: ();
$theme-colors: map-merge((
    // Use hex codes for the following brand colors
        "primary": #121837,
        "secondary": #ca7978,
        "tertiary": #BFA44D,
        "quaternary": #fcecb5,
    // Use scss vars for the following generic colors
        "light": $gray-100,
        "dark": $gray-800,
        "info": $azure,
        "warning": $yellow,
        "success": $green,
        "danger": $red,
), $theme-colors);
// stylelint-enable

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: rgba(0, 0, 0, 0.65);
$yiq-text-light: rgba(255, 255, 255, .75);
$yiq-headings-dark: theme-color("primary");
$yiq-headings-light: rgba(255, 255, 255, 1);
$yiq-link-dark: theme-color("tertiary");
$yiq-link-light: rgba(255, 255, 255, 1);
$yiq-border-dark: rgba(0, 0, 0, 0.1);
$yiq-border-light: rgba(255, 255, 255, 0.3);

// Espresso themes
$e-themes: ();
$e-themes: map-merge((
        "primary": (
                "bg": theme-color("primary"),
                "text": color-yiq(theme-color("primary")),
                "headings": color-yiq(theme-color("primary"), $yiq-headings-light, $yiq-headings-dark),
                "link": color-yiq(theme-color("primary"), $yiq-link-light, $yiq-link-dark),
                "border": color-yiq(theme-color("primary"), $yiq-border-light, $yiq-border-dark),
        ),
        "secondary": (
                "bg": theme-color("secondary"),
                "text": color-yiq(theme-color("secondary")),
                "headings": theme-color("primary"),
                "link": color-yiq(theme-color("secondary"), $yiq-link-light, $yiq-link-dark),
                "border": color-yiq(theme-color("secondary"), $yiq-border-light, $yiq-border-dark),
        ),
        "tertiary": (
                "bg": theme-color("tertiary"),
                "text": color-yiq(theme-color("tertiary")),
                "headings": color-yiq(theme-color("tertiary"), $yiq-headings-light, $yiq-headings-dark),
                "link": color-yiq(theme-color("tertiary"), $yiq-link-light, $yiq-link-dark),
                "border": color-yiq(theme-color("tertiary"), $yiq-border-light, $yiq-border-dark),
        ),
        "quaternary": (
                "bg": theme-color("quaternary"),
                "text": color-yiq(theme-color("quaternary")),
                "headings": color-yiq(theme-color("quaternary"), $yiq-headings-light, $yiq-headings-dark),
                "link": color-yiq(theme-color("quaternary"), $yiq-link-light, $yiq-link-dark),
                "border": color-yiq(theme-color("quaternary"), $yiq-border-light, $yiq-border-dark),
        ),
    //"white": (
    //        "bg": $white,
    //        "text": color-yiq($white),
    //        "headings": color-yiq($white, $yiq-headings-light, $yiq-headings-dark),
    //        "link": color-yiq($white, $yiq-link-light, $yiq-link-dark),
    //        "border": color-yiq($white, $yiq-border-light, $yiq-border-dark),
    //),
        "light": (
                "bg": theme-color("light"),
                "text": color-yiq(theme-color("light")),
                "headings": color-yiq(theme-color("light"), $yiq-headings-light, $yiq-headings-dark),
                "link": color-yiq(theme-color("light"), $yiq-link-light, $yiq-link-dark),
                "border": color-yiq(theme-color("light"), $yiq-border-light, $yiq-border-dark),
        ),
        "dark": (
                "bg": theme-color("dark"),
                "text": color-yiq(theme-color("dark")),
                "headings": color-yiq(theme-color("dark"), $yiq-headings-light, $yiq-headings-dark),
                "link": color-yiq(theme-color("dark"), $yiq-link-light, $yiq-link-dark),
                "border": color-yiq(theme-color("dark"), $yiq-border-light, $yiq-border-dark),
        ),
    //"black": (
    //        "bg": $black,
    //        "text": color-yiq($black),
    //        "headings": color-yiq($black, $yiq-headings-light, $yiq-headings-dark),
    //        "link": color-yiq($black, $yiq-link-light, $yiq-link-dark),
    //        "border": color-yiq($black, $yiq-border-light, $yiq-border-dark),
    //),
), $e-themes);

//
// Umbra, Penumbra and ambient shadows from https://material.io/guidelines/resources/shadows.html
$shadow-xs: e-shadow("xs", $black);
$shadow-sm: e-shadow("sm", $black);
$shadow-md: e-shadow("md", $black);
$shadow-lg: e-shadow("lg", $black);
$shadow-xl: e-shadow("xl", $black);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: false;
$enable-shadows: true;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: false;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1.5em;
$spacers: (
        0: 0,
        1: ($spacer * .5),
        2: ($spacer * 1),
        3: ($spacer * 1.5),
        4: ($spacer * 2),
        5: ($spacer * 2.5),
        6: ($spacer * 3)
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%
);

// Offset
//
// You can add more entries to both $offset-size maps, should you need more variation.

$offset-size-vertical: (
        0: 0,
        1: 1.25%,
        2: 2.75%,
        3: 5%,
        4: 12.75%,
        5: 25%,
        6: 50%
);

$offset-size-horizontal: (
        0: 0,
        1: 1.25vw,
        2: 2.75vw,
        3: 5vw,
        4: 12.75vw,
        5: 25vw,
        6: 50vw
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: color-yiq($body-bg);

// Links
//
// Style anchor elements.

$link-color: color-yiq($body-bg, $yiq-link-light, $yiq-link-dark);
$link-decoration: none;
$link-hover-color: $link-color;
$link-hover-decoration: underline;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: $spacer;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: ();
@include _assert-ascending($container-max-widths, "$container-max-widths");

$canvas-max-width: 2560px;
$canvas-padding-sm: e-strip-unit( $spacer ) * 1vw;
$canvas-padding-lg: $canvas-padding-sm * 2;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 5%;
$grid-gutter-width-fixed: e-strip-unit($spacer) * 2rem;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px; // Used for default and thin borders
$border-width-thick: 0.125em;
$border-color: $yiq-border-dark;

$border-radius: 0.25em;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius / 2;

$component-active-color: $white;
$component-active-bg: theme-color("primary");

$caret-width: 0.375em;

$transition-base-speed: 0.25s;
$transition-base-easing: cubic-bezier(.25, .46, .45, .94);
$transition-fade-speed: 0.375s;
$transition-fade-easing: cubic-bezier(.33, 0, .2, 1);
$transition-slide-speed: $transition-base-speed;
$transition-slide-easing: $transition-base-easing;
$transition-collapse-speed: 0.5s;
$transition-collapse-easing: cubic-bezier(0.58, 0, 0.29, 0.845); //cubic-bezier(.25, .46, .45, .94);

$transition-base: all $transition-base-speed $transition-base-easing;
$transition-fade: opacity $transition-fade-speed $transition-fade-easing;
$transition-collapse: height $transition-collapse-speed $transition-collapse-easing;

$transform-scale-zoom: scale(1.25, 1.25);

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif;
$font-family-serif: "Playfair Display", Times, serif; // "IBM Plex Serif",
$font-family-monospace: "Menlo", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", Courier, monospace; // "IBM Plex Mono",
$font-family-base: $font-family-sans-serif;

$font-size-root: 16px;
$font-size-base: 1em; // Assumes the browser default, typically `16px`
$font-size-lg: 1.25em;
$font-size-sm: .875em;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;

$line-height-base: e-strip-unit($spacer);
$line-height-lg: $line-height-base;
$line-height-sm: 1.25;

$headings-margin-top: $spacer;
$headings-margin-bottom: ($spacer / 2);
$headings-font-family: inherit;
$headings-font-weight: $font-weight-bold;
$headings-line-height: $line-height-sm;
$headings-color: color-yiq($body-bg, $yiq-headings-light, $yiq-headings-dark);

$h1-font-size: 2.5em;
$h2-font-size: 2em;
$h3-font-size: 1.125em;
$h4-font-size: 1.0625em;

$h1-font-weight: $font-weight-bold;
$h2-font-weight: $font-weight-bold;
$h3-font-weight: $font-weight-bold;
$h4-font-weight: $font-weight-light;

$h1-font-family: $font-family-serif;
$h2-font-family: $font-family-serif;
$h3-font-family: $headings-font-family;
$h4-font-family: $headings-font-family;

// $display1-size: 6rem;
// $display2-size: 5.5rem;
// $display3-size: 4.5rem;
// $display4-size: 3.5rem;

// $display1-weight:     300;
// $display2-weight:     300;
// $display3-weight:     300;
// $display4-weight:     300;
// $display-line-height: $headings-line-height;

$lead-font-family: inherit;
$lead-font-size: 1.5em;
$lead-font-weight: $font-weight-light;

$small-font-size: $font-size-sm;
$small-font-weight: inherit;

$text-muted: 0.65;

$blockquote-small-color: $gray-600;
$blockquote-font-size: ($font-size-base * 1.25);

$hr-border-color: $border-color;
$hr-border-width: $border-width;
$hr-margin: $spacer 0;

$list-inline-padding: $spacer / 2;

// Espresso components
//

// Use hamburger navigation below this breakpoint.
$nav-hamburger-breakpoint: lg;

// Loading overlay.
$loading-bg: $body-bg;
$loading-color: theme-color("primary");
$loading-color-backdrop: color-yiq($loading-bg, $yiq-border-light, $yiq-border-dark);
$loading-size: 60px; // Use px value to avoid jump in size when css loads async.

// Settings for header.
$header-bg: $white;
$header-color: color-yiq($header-bg, $yiq-headings-light, $yiq-headings-dark);
$header-border-color: color-yiq($header-bg, $yiq-border-light, $yiq-border-dark);
$header-logo-height-sm: $spacer * 1.25;
$header-logo-height-lg: $spacer * 1.25;
$header-logo-margin-sm: ($spacer * 1) 0;
$header-logo-margin-lg: ($spacer * 1) 0;
$header-logo-padding-sm: 0;
$header-logo-padding-lg: 0;

// Settings for sections, mosaic, tile and banner paragraphs.
$section-height-xsmall: 17.5vw;
$section-height-small: 25vw;
$section-height-medium: 37.5vw;
$section-height-large: 50vw;
$section-height-xlarge: 62.5vw;

$section-heights: ();
$section-heights: map-merge((
        "xs": $section-height-xsmall,
        "sm": $section-height-small,
        "md": $section-height-medium,
        "lg": $section-height-large,
        "xl": $section-height-xlarge,
), $section-heights);

// Back to top link.
$back-to-top-color: $header-color;
$back-to-top-bg: $header-bg;

// Settings for footer.
$footer-bg: e-theme-color("primary", "bg");
$footer-color: color-yiq($footer-bg);
$footer-link-color: color-yiq($footer-bg, $yiq-link-light, $yiq-link-dark);
$footer-headings-color: color-yiq($footer-bg, $yiq-headings-light, $yiq-headings-dark);
$footer-social-icon-color: $footer-link-color;
$footer-copyright-bg: e-theme-color("quaternary", "bg");
$footer-copyright-color: theme-color("primary");
$footer-copyright-link-color: $footer-copyright-color;
$footer-copyright-link-hover-color: color-yiq($footer-copyright-bg, $yiq-headings-light, $yiq-headings-dark);

// Settings for sticky CTA section.
$cta-bg: e-theme-color("light", "bg");
$cta-btn-color: theme-color("primary");
$cta-icon-color: $cta-btn-color;
$cta-icon-hover-color: $cta-icon-color;
$cta-link-color: e-theme-color("light", "headings");
$cta-link-hover-color: $cta-icon-color;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-font-size: $font-size-sm;

$table-cell-padding: ($spacer * .5) ($spacer * 0.75);
$table-cell-padding-sm: ($spacer * 0.375) ($spacer * 0.375);

$table-bg: transparent;
$table-accent-bg: rgba($black, .05);
$table-hover-bg: rgba($black, .075);
$table-active-bg: $table-hover-bg;

$table-border-width: $border-width;
$table-border-color: $border-color;

$table-head-bg: $gray-200;
$table-head-color: $gray-700;

$table-dark-bg: $gray-900;
$table-dark-accent-bg: rgba($white, .05);
$table-dark-hover-bg: rgba($white, .075);
$table-dark-border-color: lighten($gray-900, 7.5%);
$table-dark-color: $body-bg;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.
$input-btn-padding-y: .375em;
$input-btn-padding-x: .75em;
$input-btn-line-height: $line-height-sm;

$input-btn-focus-width: 0; //.125em;
$input-btn-focus-color: rgba(0, 0, 0, 0);
$input-btn-focus-box-shadow: none; // 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.375em;
$input-btn-padding-x-sm: 0.5em;
$input-btn-line-height-sm: $input-btn-line-height;

$input-btn-padding-y-lg: $input-btn-padding-y;
$input-btn-padding-x-lg: $input-btn-padding-x;
$input-btn-line-height-lg: $input-btn-line-height;

$btn-font-family: inherit;
$btn-font-weight: $font-weight-bold;
$btn-line-height: $input-btn-line-height;

$btn-font-size: .875em;
$btn-padding-y: 1em;
$btn-padding-x: 2.5em;

$btn-font-size-lg: 1.125em;
$btn-padding-y-lg: .875em;
$btn-padding-x-lg: 2.5em;

$btn-font-size-sm: .75em;
$btn-padding-y-sm: .5em;
$btn-padding-x-sm: 1em;

$btn-box-shadow: none; // $shadow-xs;
$btn-active-box-shadow: none; // $shadow-xs;

$btn-link-disabled-color: $gray-600;

$btn-block-spacing-y: $spacer * 0.75;

$btn-border-width: .125em;
// Allows for customizing button radius independently from global border radius
$btn-border-radius: 10em;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;

$btn-transition: none;

// Button color variants
$btn-colors: ();
$btn-colors: map-merge((
        "primary": (
                "bg": theme-color("primary"),
                "border": theme-color("primary"),
                "color": color-yiq(theme-color("primary"), $yiq-headings-light, $yiq-headings-dark),
                "hover-bg": transparent,
                "hover-border": theme-color("primary"),
                "hover-color": theme-color("primary"),
        ),
        "secondary": (
                "bg": theme-color("secondary"),
                "border": theme-color("secondary"),
                "color": color-yiq(theme-color("secondary"), $yiq-headings-light, $yiq-headings-dark),
                "hover-bg": transparent,
                "hover-border": theme-color("secondary"),
                "hover-color": theme-color("secondary"),
        ),
        "tertiary": (
                "bg": theme-color("light"),
                "border": theme-color("light"),
                "color": color-yiq(theme-color("light")),
                "hover-bg": theme-color("light"),
                "hover-border": theme-color("light"),
                "hover-color": color-yiq(theme-color("light"), $yiq-headings-light, $yiq-headings-dark),
        ),
        "success": (
                "bg": theme-color("primary"),
                "border": theme-color("primary"),
                "color": color-yiq(theme-color("primary"), $yiq-headings-light, $yiq-headings-dark),
                "hover-bg": theme-color("primary"),
                "hover-border": theme-color("primary"),
                "hover-color": color-yiq(theme-color("primary"), $yiq-headings-light, $yiq-headings-dark),
        )
), $btn-colors);

// Forms
$form-font-size: $font-size-sm;

$input-bg: $white;
$input-disabled-bg: $gray-200;

$input-color: $body-color;
$input-border-color: $border-color;
$input-btn-border-width: $border-width; // For form controls and buttons
$input-box-shadow: 0 0 0 rgba(0, 0, 0, 0);

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;

$input-focus-bg: $input-bg;
$input-focus-border-color: $input-border-color;
$input-focus-color: $input-color;

$input-placeholder-color: $gray-600;

$input-height-border: $input-btn-border-width * 2;

$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2);
$input-height: calc(#{$input-height-inner} + #{$input-height-border});

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2);
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border});

$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2);
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border});

$input-transition: border-color $transition-fade-speed $transition-fade-easing, box-shadow $transition-fade-speed $transition-fade-easing;

$form-text-margin-top: $spacer / 5;

$form-check-margin-bottom: $spacer / 2;
$form-check-input-gutter: $spacer;
$form-check-input-margin-y: $spacer / 4;
$form-check-input-margin-x: $spacer / 4;

$form-check-inline-margin-x: $spacer / 5;

$form-group-margin-bottom: $spacer;

$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;

$custom-control-gutter: $spacer * 1.25;
$custom-control-spacer-y: $spacer / 4;
$custom-control-spacer-x: 0;

$custom-control-indicator-size: $spacer / 1.5;
$custom-control-indicator-bg: $gray-300;
$custom-control-indicator-bg-size: 90% 90%;
$custom-control-indicator-box-shadow: none; //inset 0 0.0625em 0.125em $border-color;

$custom-control-indicator-disabled-bg: $gray-100;
$custom-control-description-disabled-color: $gray-600;

$custom-control-indicator-checked-color: $white;
$custom-control-indicator-checked-bg: theme-color("primary");
$custom-control-indicator-checked-box-shadow: none;

$custom-control-indicator-focus-box-shadow: none; //0 0 0 1px $body-bg, $input-btn-focus-box-shadow;

$custom-control-indicator-active-color: $white;
$custom-control-indicator-active-bg: lighten(theme-color("primary"), 35%);
$custom-control-indicator-active-box-shadow: none;

$custom-checkbox-indicator-border-radius: $input-border-radius;
$custom-checkbox-indicator-bg-size: 90% 90%;

$custom-checkbox-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg fill='#{$custom-control-indicator-checked-color}' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E"), "#", "%23");

$custom-checkbox-indicator-indeterminate-bg: $custom-control-indicator-bg;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-bg;
$custom-checkbox-indicator-icon-indeterminate: none;
$custom-checkbox-indicator-indeterminate-box-shadow: none;

$custom-radio-indicator-border-radius: 50%;
$custom-radio-indicator-bg-size: 45% 45%;
$custom-radio-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg fill='#{$custom-control-indicator-checked-color}' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z'/%3E%3C/svg%3E"), "#", "%23");

$custom-select-padding-y: $input-btn-padding-y;
$custom-select-padding-x: $input-btn-padding-x;
$custom-select-height: $input-height;
$custom-select-indicator-padding: 1em; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height: $input-btn-line-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-600;
$custom-select-bg: $white;
$custom-select-disabled-bg: $gray-200;
$custom-select-bg-size: 8px 10px; // In pixels because image dimensions
$custom-select-indicator-color: $gray-800;
$custom-select-indicator: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23");
$custom-select-border-width: $input-btn-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $input-border-radius;

$custom-select-focus-border-color: lighten(theme-color("primary"), 25%);
$custom-select-focus-box-shadow: inset 0 1px 2px rgba($black, .075), 0 0 5px rgba($custom-select-focus-border-color, .5);

$custom-select-font-size-sm: $font-size-sm;
$custom-select-height-sm: $input-height-sm;

$custom-file-height: $input-height;
$custom-file-width: 14em;
$custom-file-focus-box-shadow: 0 0 0 .075em $white, 0 0 0 .2em theme-color("primary");

$custom-file-padding-y: $input-btn-padding-y;
$custom-file-padding-x: $input-btn-padding-x;
$custom-file-line-height: $input-btn-line-height;
$custom-file-color: $input-color;
$custom-file-bg: $input-bg;
$custom-file-border-width: $input-btn-border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow: $input-box-shadow;
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $input-group-addon-bg;
$custom-file-text: (
        placeholder: (
                en: "Choose file..."
        ),
        button-label: (
                en: "Browse"
        )
);

// Form validation
$form-feedback-valid-color: theme-color("success");
$form-feedback-invalid-color: theme-color("danger");

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10em;
$dropdown-padding-y: $spacer / 1.5;
$dropdown-spacer: 0;
$dropdown-bg: $white;
$dropdown-border-color: $border-color;
$dropdown-border-radius: $border-radius;
$dropdown-border-width: 0;
$dropdown-divider-bg: $gray-200;
$dropdown-box-shadow: $shadow-sm;

$dropdown-link-color: color-yiq($dropdown-bg);
$dropdown-link-hover-bg: theme-color("light");
$dropdown-link-hover-color: color-yiq($dropdown-link-hover-bg);

$dropdown-link-active-color: $dropdown-link-hover-color;
$dropdown-link-active-bg: $dropdown-link-hover-bg;

$dropdown-link-disabled-color: $gray-500;

$dropdown-item-padding-y: $dropdown-padding-y / 2;
$dropdown-item-padding-x: $spacer;

$dropdown-header-color: color-yiq($dropdown-bg, $yiq-headings-light, $yiq-headings-dark);

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;

// Navs

$nav-link-padding-y: 0.5em;
$nav-link-padding-x: 1em;
$nav-link-disabled-color: $gray-600;

$nav-tabs-border-color: $border-color;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-200;
$nav-tabs-link-active-color: $gray-700;
$nav-tabs-link-active-bg: $body-bg;
$nav-tabs-link-active-border-color: $border-color;

$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

// Navbar

$navbar-padding-y: ($spacer / 2);
$navbar-padding-x: $spacer;

$navbar-brand-font-size: $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-height: ($font-size-base * $line-height-base + $nav-link-padding-y * 2);
$navbar-brand-padding-y: ($navbar-brand-height - $nav-link-height) / 2;

$navbar-toggler-padding-y: .25em;
$navbar-toggler-padding-x: .75em;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;

$navbar-dark-color: rgba($white, .5);
$navbar-dark-hover-color: rgba($white, .75);
$navbar-dark-active-color: rgba($white, 1);
$navbar-dark-disabled-color: rgba($white, .25);
$navbar-dark-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$navbar-dark-toggler-border-color: rgba($white, .1);

$navbar-light-color: rgba($black, .5);
$navbar-light-hover-color: rgba($black, .7);
$navbar-light-active-color: rgba($black, .9);
$navbar-light-disabled-color: rgba($black, .3);
$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$navbar-light-toggler-border-color: rgba($black, .1);

// Pagination

$pagination-padding-y: .5em;
$pagination-padding-x: .75em;
$pagination-padding-y-sm: .25em;
$pagination-padding-x-sm: .5em;
$pagination-padding-y-lg: .75em;
$pagination-padding-x-lg: 1.5em;
$pagination-line-height: 1.25;

$pagination-color: $link-color;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-color: $border-color;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $border-color;

$pagination-active-color: $white;
$pagination-active-bg: $link-color;
$pagination-active-border-color: $link-color;

$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $border-color;

// Jumbotron

$jumbotron-padding: 2em;
$jumbotron-bg: $gray-200;

// Cards

$card-spacer-y: $spacer * 0.75;
$card-spacer-x: $spacer * 1;
$card-border-width: $border-width;
$card-border-radius: $border-radius;
$card-border-color: $border-color;
$card-shadow: $shadow-md;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-bg: $body-bg;
$card-cap-bg: inherit;

$card-img-overlay-padding: 1.25em;

$card-deck-margin: ($grid-gutter-width / 2);

$card-columns-count: 3;
$card-columns-gap: 1.25em;
$card-columns-margin: $card-spacer-y;

// Tooltips

$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $black;
$tooltip-opacity: .9;
$tooltip-padding-y: $spacer / 5;
$tooltip-padding-x: $spacer / 2.5;
$tooltip-margin: 0;

$tooltip-arrow-width: $spacer / 2.5;
$tooltip-arrow-height: $spacer / 2.5;
$tooltip-arrow-color: $tooltip-bg;

// Popovers

$popover-inner-padding: 1px;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: $border-color;
$popover-box-shadow: $shadow-md;

$popover-header-bg: darken($popover-bg, 3%);
$popover-header-color: $headings-color;
$popover-header-padding-y: $spacer / 2;
$popover-header-padding-x: $spacer / 1.5;

$popover-body-color: $body-color;
$popover-body-padding-y: $spacer / 2;
$popover-body-padding-x: $spacer / 1.5;

$popover-arrow-width: 10px;
$popover-arrow-height: 5px;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-width: ($popover-arrow-width + 1px);
$popover-arrow-outer-color: fade-in($popover-border-color, .05);

// Badges

$badge-color: $white;
$badge-font-size: 0.75em;
$badge-font-weight: $font-weight-normal;
$badge-padding-y: $spacer / 4;
$badge-padding-x: $spacer / 2;

$badge-pill-padding-x: $badge-padding-y;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10em;

// Modals

// Padding applied to the modal body
$modal-inner-padding: ($spacer * 1.25) ($spacer * 1);

$modal-dialog-margin: $spacer / 2;
$modal-dialog-margin-y-sm-up: 5vmax;

$modal-title-line-height: $line-height-sm;

$modal-content-bg: $white;
$modal-content-border-color: $border-color;
$modal-content-border-width: $border-width;
$modal-content-box-shadow-xs: $shadow-md;
$modal-content-box-shadow-sm-up: $shadow-md;

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: .65;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding: ($spacer * 0.875) ($spacer * 1) ($spacer * 0.75);

$modal-lg: 75%;
$modal-md: 50%;
$modal-sm: 25%;

$modal-transition: transform $transition-slide-speed $transition-slide-easing;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: .75em;
$alert-padding-x: 1.25em;
$alert-margin-bottom: 1em;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;

$alert-bg-level: -9.5;
$alert-border-level: -7.5;
$alert-color-level: 9.5;

// Progress bars

$progress-height: 1em;
$progress-font-size: .75em;
$progress-bg: $gray-200;
$progress-border-radius: $border-radius;
$progress-box-shadow: $shadow-sm;
$progress-bar-color: $white;
$progress-bar-bg: theme-color("primary");
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width .6s ease;

// List group

$list-group-bg: $white;
$list-group-border-color: $border-color;
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;

$list-group-item-padding-y: .75em;
$list-group-item-padding-x: 1.25em;

$list-group-hover-bg: $gray-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;

$list-group-action-color: $gray-700;
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-200;

// Image thumbnails

$thumbnail-padding: .25em;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: $border-color;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: $shadow-sm;
$thumbnail-transition: all $transition-base-speed $transition-base-easing;

// Figures

$figure-caption-font-size: $font-size-sm;
$figure-caption-color: $text-muted;

// Breadcrumbs

$breadcrumb-padding-y: $spacer / 1;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: ($spacer / 4) ($spacer / 2);

$breadcrumb-color: $body-color;
$breadcrumb-bg: none;
$breadcrumb-divider-color: inherit;
$breadcrumb-active-color: $headings-color;
$breadcrumb-divider: "\203A";

// Collapse

$collapse-peek-height-sm: 25vh;
$collapse-peek-height-md: 33vh;
$collapse-peek-height-lg: 50vh;

// Carousel

$carousel-control-color: $gray-200;
$carousel-control-size: 4em;
$carousel-control-opacity: 0.875;
$carousel-control-width: $carousel-control-size * 1.5;

$carousel-control-prev-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$carousel-control-color}' d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'/%3E%3C/svg%3E"), "#", "%23");
$carousel-control-next-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$carousel-control-color}' d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'/%3E%3C/svg%3E"), "#", "%23");

$carousel-indicator-width: 1em;
$carousel-indicator-height: 1em;
$carousel-indicator-spacer: 0.25em;
$carousel-indicator-active-bg: rgba($white, $carousel-control-opacity);

$carousel-caption-width: 90%;
$carousel-caption-color: $white;
$carousel-caption-bg: $gray-900;
$carousel-caption-height: $spacer * 10;

$carousel-transition: transform $transition-slide-speed $transition-slide-easing;

$image-gallery-bg: e-theme-color("light", "bg");
$image-gallery-controls-bg: e-theme-color("dark", "bg");
$image-gallery-controls-color: e-theme-color("dark", "headings");

// Close

$close-font-size: $font-size-base * 1.5;
$close-font-weight: $font-weight-bold;
$close-color: inherit;
$close-text-shadow: none;

// Code

$code-font-size: $font-size-sm;
$code-padding-y: .2em;
$code-padding-x: .4em;
$code-color: #bd4147;
$code-bg: $gray-100;

$kbd-color: $white;
$kbd-bg: $gray-900;

$pre-color: $gray-900;
$pre-scrollable-max-height: 340px;
