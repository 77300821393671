.frontpage .e-header:not(.reset-transform-containing-block) .e-logo-link {
    &::before, &::after {
        content: '';
        position: absolute;
        bottom: 0;
        top: 0;
        height: 100%;
        background: $white;
    }
    &::before {
        animation: reveal-logo-left 1s ease;
    }
    &::after {
        animation: reveal-logo-right 1s ease;
    }
}

.p-section .e-reveal-done .ita-box-right-bottom {
    &::before {
        animation: reveal-from-left-box $transition-collapse-speed ease;
        background-image: radial-gradient(theme-color("secondary") 50%, transparent 0);
        right: 0;
    }
}

/* ANIMATION */

$reveal-color-main: lighten(theme-color("primary"), 2.5);
$reveal-color-secondary: theme-color("primary");

.animation-from-left {
    &::before {
        animation: reveal-from-left 2s ease;
    }
    &::after {
        animation: reveal-from-left 2s 1s ease;
    }
}

.animation-from-left, .e-reveal-done {
    &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
    }

    &::before {
        background-color: $reveal-color-main;
    }

    &::after {
        background-color: $reveal-color-secondary;
        animation-delay: 0.5s;
    }
}

@keyframes reveal-from-left-box {
    0% {
        width: 0;
        right: 0;
    }

    100% {
        width: 93%;
    }
}

@keyframes reveal-logo-right {
    0% {
        left: 51%;
        right: -5%;
        transform: skew(-15deg, 0);
    }
    65% {
        left: 51%;
        right: -5%;
        transform: skew(-15deg, 0);
    }
    100% {
        left: 100%;
        right: 0;
        transform: skew(0, 0);
    }
}

@keyframes reveal-logo-left {
    0% {
        left: 0;
        right: 65%;
    }
    100% {
        left: 65%;
        right: 65%;
    }
}

@keyframes reveal-from-left {
    0% {
        width: 0;
        left: 0;
    }
    65% {
        width: 100%;
        left: 0;
    }

    100% {
        width: 0;
        left: 100%;
    }
}

@keyframes ita-text-animation-slow {
    0% {
        opacity: 0;
        transform: translateY(0);
    }
    50% {
        opacity: 0;
        transform: translateY(1em);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes ita-opacity-animation-slow {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes ita-opacity-animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes imgHidden {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}