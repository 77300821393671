.p-carousel {
    height: auto;
    margin-bottom: $paragraph-margin-bottom;
    @include media-breakpoint-down(md) {
        .carousel-inner {
            box-sizing: content-box;
            padding-bottom: $carousel-caption-height;
            background: $carousel-caption-bg;
        }
        .p-carousel-item {
            &::before {
                background: none;
            }
        }
        .carousel-caption {
            box-sizing: border-box;
            height: $carousel-caption-height;
            bottom: auto;
            top: 100%;
        }
        .carousel-indicators {
            bottom: $carousel-caption-height - $spacer;
        }
        .carousel-control-prev,
        .carousel-control-next {
            margin-bottom: $carousel-caption-height;
        }
    }
    &.p-carousel-fullscreen {
        .carousel-inner {
            box-sizing: inherit;
            padding-bottom: 0;
            background: none;
        }
        .p-carousel-item {
            &::before {
                background: linear-gradient(11deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 45%);
            }
        }
        .carousel-caption {
            height: auto;
            bottom: 0;
            top: auto;
        }
        .carousel-control-prev,
        .carousel-control-next {
            padding-bottom: 0;
        }
        .carousel-indicators {
            bottom: $spacer / 2;
        }
    }
}

.p-carousel-item {
    background-size: cover;
    background-position: 50% 50%;
    &:not(.p-carousel-item-gradient-disabled)::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(22deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 60%);
        @include media-breakpoint-down(md) {
            background: none;
        }
    }
}


