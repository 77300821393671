.p-tile {
    padding: 0;
    .p-tile-container {
        position: absolute;
        display: flex;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        overflow: hidden;
    }

    .p-txt-container {
        position: relative;
        z-index: 2;
        width: 100%;
        padding: $spacer * 1.375 $spacer * 1;
    }

}

.p-tile-txt-small {
    font-size: $font-size-sm;
}

.p-tile-txt-medium {
    font-size: $font-size-base;
}

.p-tile-txt-large {
    font-size: $font-size-lg;
}

.p-tile-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;

}

//Image tile specific styling
.p-tile-img {
    .p-img-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        overflow: hidden;
    }
    .p-img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        transition: transform $transition-fade-speed;
        &:not(.p-tile-gradient-disabled)::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(22deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    &.p-tile-zoom:hover {
        .p-img {
            transform: $transform-scale-zoom;
        }
    }
}

// Heights
.p-tile-height-auto {
    .p-txt-container {
        position: static;
    }
}

.p-tile-height-xs {
    height: 25vh;
    min-height: 1em * 25 / 2;
}

.p-tile-height-sm {
    height: 33.33333vh;
    min-height: 1em * 33.33333 / 2;
}

.p-tile-height-md {
    height: 50vh;
    min-height: 1em * 50 / 2;
}

.p-tile-height-lg {
    height: 66.66666vh;
    min-height: 1em * 66.66666 / 2;
}

.p-tile-height-xl {
    height: 75vh;
    min-height: 1em * 75 / 2;
}

.p-tile-height-fullscreen {
    height: 100vh;
    @include media-breakpoint-up(sm) {
        min-height: 1em * 100 / 2;
    }
}

// Content alignment
.p-tile {
    &.p-tile-center-top {
        .p-tile-container {
            align-items: flex-start;
        }
        .p-txt-wrapper {
            justify-content: center;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 65%);
        }
    }
    &.p-tile-center-middle {
        .p-tile-container {
            align-items: center;
        }
        .p-txt-wrapper {
            justify-content: center;
        }
        .p-img-container .p-img::after {
            background: rgba(0, 0, 0, 0.55);
        }
    }
    &.p-tile-center-bottom {
        .p-tile-container {
            align-items: flex-end;
        }
        .p-txt-wrapper {
            justify-content: center;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 65%);
        }
    }
    &.p-tile-left-top {
        .p-tile-container {
            align-items: flex-start;
        }
        .p-txt-wrapper {
            justify-content: flex-start;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(157deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    &.p-tile-left-middle {
        .p-tile-container {
            align-items: center;
        }
        .p-txt-wrapper {
            justify-content: flex-start;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    &.p-tile-left-bottom {
        .p-tile-container {
            align-items: flex-end;
        }
        .p-txt-wrapper {
            justify-content: flex-start;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(22deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    &.p-tile-right-top {
        .p-tile-container {
            align-items: flex-start;
        }
        .p-txt-wrapper {
            justify-content: flex-end;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(202deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    &.p-tile-right-middle {
        .p-tile-container {
            align-items: center;
        }
        .p-txt-wrapper {
            justify-content: flex-end;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    &.p-tile-right-bottom {
        .p-tile-container {
            align-items: flex-end;
        }
        .p-txt-wrapper {
            justify-content: flex-end;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(338deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    &.p-tile-gradient-disabled {
        .p-img-container .p-img::after {
            background: none;
        }
    }
}
