.p-card {
    display: flex;
    margin-bottom: $paragraph-margin-bottom;
    .card {
        width: 100%;
    }
    &.p-card-top-bottom {
        .card {
            display: flex;
            flex-direction: column;
        }
    }
    .p-txt-container {
        font-size: $font-size-sm;
        width: 100%;
        flex: 1 1 auto;
        .card-body {
        }
        h1,
        h2,
        h3,
        h4,
        p,
        ul,
        table {
            margin-bottom: 0.5rem;
        }
        :last-child {
            margin-bottom: 0;
        }
    }
    .p-img-container {
        width: 100%;
        flex: 0 0 auto;
        .card-body {
            padding-bottom: 0;
        }
        .col-pos-center {
            margin-left: auto;
            margin-right: auto;
        }
        .col-pos-right {
            margin-left: auto;
        }
        .p-img[src$="svg"] {
            width: 100%;
        }
    }
}

.p-card-img-bleed {
    .p-img-container {
        .card-body {
            padding: 0;
        }
        .row {
            margin: 0;
        }
        .col-12 {
            padding: 0;
        }
        .p-img {
            border-radius: $card-border-radius $card-border-radius 0 0;
        }
    }
}

.p-card-link {
    .card {
        cursor: pointer;
    }
}

// Text size
.p-card-txt-small {
    .p-txt-container {
        font-size: $font-size-sm;
    }
}

.p-card-txt-medium {
    .p-txt-container {
        font-size: 1em;
    }
}

.p-card-txt-large {
    .p-txt-container {
        font-size: $lead-font-size;
    }
}

// Shadows and hover state
.p-card {
    &:not(.p-card-hover-disabled):hover {
        .card {
            box-shadow: $shadow-lg;
        }
    }
}

.p-card-shadow-none {
    .card {
        box-shadow: none;
    }
    &:not(.p-card-hover-disabled):hover {
        .card {
            box-shadow: none;
        }
    }
}

.p-card-shadow-small {
    .card {
        box-shadow: $shadow-sm;
    }
    &:not(.p-card-hover-disabled):hover {
        .card {
            box-shadow: $shadow-md;
        }
    }
}

.p-card-shadow-medium {
    .card {
        box-shadow: $shadow-md;
    }
    &:not(.p-card-hover-disabled):hover {
        .card {
            box-shadow: $shadow-lg;
        }
    }
}

.p-card-shadow-large {
    .card {
        box-shadow: $shadow-lg;
    }
    &:not(.p-card-hover-disabled):hover {
        .card {
            box-shadow: $shadow-xl;
        }
    }
}

// Padding
.p-card-padding-small {
    .card-body {
        padding: $spacer * 0.5;
    }
}

.p-card-padding-medium {
    .card-body {
        padding: $spacer;
    }
}

.p-card-padding-large {
    .card-body {
        padding: $spacer * 1.5;
    }
}


// Border width
.p-card-border-thin {
    .card {
        border-width: $border-width;
    }
}

.p-card-border-thick {
    .card {
        border-width: $border-width-thick;
    }
}
