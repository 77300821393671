.p-width-auto {
    .e-section > .container-fluid > .row > div > .row > & {
        @include media-breakpoint-up(xs) {
            @include make-col-ready();
            @include make-col(12);
        }
    }
    .e-section > .container-fluid > .row > .col-lg-12 > .row > & {
        @include media-breakpoint-up(md) {
            @include make-col(10);
            margin-right: percentage( ( $grid-columns - 10) / 2 / $grid-columns);
            margin-left: percentage( ( $grid-columns - 10) / 2 / $grid-columns);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(8);
            margin-right: percentage( ( $grid-columns - 8) / 2 / $grid-columns);
            margin-left: percentage( ( $grid-columns - 8) / 2 / $grid-columns);
        }
    }
}

.p-img {
    max-width: 100%;
    height: auto;
}

.p-img-circle {
    @extend .rounded-circle;
}

.p-img-corner-rounded {
    @extend .rounded;
}

.p-img-corner-sharp {
    @extend .rounded-0;
}

.p-img-shadow-none {
    box-shadow: none;
}

.p-img-shadow-small {
    box-shadow: $shadow-sm;
}

.p-img-shadow-medium {
    box-shadow: $shadow-md;
}

.p-img-shadow-large {
    box-shadow: $shadow-lg;
}

.p-img-border-none {
    border: 0;
}

.p-img-border-thin {
    border: $border-width solid $border-color;
}

.p-img-border-thick {
    border: $border-width-thick solid $border-color;
}

.p-img-border-double {
    @extend .p-img-border-thin;
    padding: $border-width-thick / 1.5;
}

.p-img-link {
    text-decoration: none;
}
